import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { show_alert } from "../functions";

const ShowGuides = () => {
  const url = "api.envioslulu.com/api";
  const [guides, setGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState(null);

  useEffect(() => {
    getGuides();
  }, []);

  const handleButtonClick = (guide) => {
    setSelectedGuide(guide);
  };

  const getGuides = async () => {
    //const response = await axios.get(url);
    //setGuides(response.data);
    let tempData = [
      { id: "1", status: "Completed", date: "21/06/2024 22:35" },
      { id: "2", status: "Processed", date: "21/06/2024 2:35" },
    ];
    setGuides(tempData);
  };

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12 col-lg-8 offset-0 offset-lg-2">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ESTADO</th>
                    <th>FECHA</th>
                    <th>OPCIONES</th>
                  </tr>
                </thead>
                <tbody className="table-group.divider">
                  {guides.map((guide, id) => (
                    <tr key={guide.id}>
                      <td>{guide.id} </td>
                      <td>{guide.status} </td>
                      <td>{guide.date}</td>
                      <td>
                        <button
                          className="btn btn-dark"
                          data-bs-toggle="modal"
                          data-bs-target="#modalInfoGuide"
                          onClick={() => handleButtonClick(guide)}
                        >
                          <i className="fa-solid fa-eye"></i>
                          {" Ver detalles"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="modalInfoGuide" className="modal fade" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <label className="h5">1</label>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="close"
              >
                {" "}
              </button>
            </div>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowGuides;
