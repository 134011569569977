import { Routes, Route, BrowserRouter } from "react-router-dom";
import ShowGuides from "./components/ShowGuides";
import LoginForm from "./components/LoginForm/LoginForm";
import TrackingView from "./components/TrackerPage/Tracking";

function App() {
  return (
    //<div>
    // <LoginForm />
    // </div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TrackingView></TrackingView>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
