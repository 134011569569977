import React, { Component, useEffect, useState } from "react";
import "../../styles/tracking.css";
import trackingLogo from "../Assets/lulu-logo.png";
import banner from "../Assets/banner.jpeg";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import jumpIcon from "../Assets/logo-70-63693dbe6ef5b.png";
import mecafle from "../Assets/logo-mercado-envios-icon-768.png";
import famakep from "../Assets/media.png";
import fallabella from "../Assets/png-transparent-falabella-sears-shopping-gift-card-banco-falabella-cdr-angle-text.png";
import shopify from "../Assets/shopify.png";
import apir from "../Assets/web-development.png";
import woo from "../Assets/woocommerce.png";

const TrackingView = () => {
  const [isSelect, setIsSelect] = useState(false);
  const [imageActive, setImageActive] = useState();
  const [historyOrder, setHistoryOrder] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    return () => {};
  }, []);

  const [show, setShow] = useState(false);

  // if (show) {
  //   return (
  //     <Alert variant="danger" onClose={() => setShow(false)} dismissible>
  //       <Alert.Heading>¡OH, hemos tenido un error!</Alert.Heading>
  //       <p>
  //       ¡Por favor, ingrese un número de guía correcto!.
  //       </p>
  //     </Alert>
  //   );
  // }

  const getTracking = () => {
    axios
      .get(`https://api.envioslulu.com/api/shipments/tracking?id=${textSearch}`)
      .then((response) => {
        setHistoryOrder(response.data);
        console.log(historyOrder);
        setShow(false);
      })
      .catch((error) => {
        setShow(true);
        setHistoryOrder([]);
        console.log(show);

        console.error("Error fetching data:", error);
      });
  };

  const changeSize = (img) => {
    setIsSelect(true);
    setImageActive(img);
  };
  console.log(textSearch);
  console.log(historyOrder);

  return (
    <div className="containerbodyApp">
      <div className="containerApp">
        <div className="container-banner">
          <img className="bannerPng" src={banner} alt="" />
        </div>
        <div className="central-container">
          <div className=" p-3  rounded shadowCard">
            <div className="divlogo">
              <img className="logoTracking" src={trackingLogo} alt="" />
            </div>
            <div className="d-flex justify-content-center  text-logo">
              Rastrea la ubicación de tu envío
            </div>
            <div className="d-flex justify-content-center mt-3 containmerInput">
              <input
                className="inputCustom"
                type="text"
                placeholder="Numero de Guia"
                onChange={(event) => setTextSearch(event.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center mt-2 ">
              <button className="buttonCustom" onClick={getTracking}>
                Buscar
              </button>
            </div>
          </div>

          {show ? (
            <div>
              <Alert show={show} variant="danger">
                <Alert.Heading>¡OH, hemos tenido un error!</Alert.Heading>
                <p>¡Por favor, ingrese un número de guía correcto!.</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => setShow(false)}
                    variant="outline-danger"
                  >
                    Cerrar
                  </Button>
                </div>
              </Alert>
            </div>
          ) : (
            <div className="history-order mb-2">
              {historyOrder.data?.map((movement) => (
                <div className="movement">
                  <div className="container-movemment">
                    <b> Movimiento: </b>{" "}
                    <span className="text-detail">{movement.statusName}</span>
                  </div>
                  <hr className="espacing-hr" />
                  <div className="container-movemment">
                    <b> Fecha de moviento: </b>{" "}
                    <span className="text-detail">
                      {new Date(movement.createdAt).toLocaleString("es-ES")}
                    </span>
                  </div>
                  <hr className="espacing-hr" />
                  <div className="container-movemment">
                    <b> Codigo de estado: </b>{" "}
                    <span className="text-detail">{movement.statusCode}</span>
                  </div>
                  <hr className="espacing-hr" />
                  <div className="container-movemment">
                    <b> Comentario: </b>{" "}
                    <span className="text-detail">
                      {movement.taskFailedReason}
                    </span>
                  </div>
                  <hr className="espacing-hr" />
                  <div className="container-evidence d-flex">
                    <b> Evidencia: </b>
                  </div>
                  <div className="text-detail  w-100">
                    {movement.evidence?.map((evi) => (
                      <div className="container-image-evidence">
                        <p
                          className={"image-evidence"}
                          onClick={() => changeSize(evi)}
                        >
                          {evi.substring(0, 40) + "..."}
                        </p>

                        <div className={isSelect ? "" : "invisible"}>
                          <img
                            className={isSelect ? "image-resize" : "invisible"}
                            src={imageActive}
                            alt=""
                          />
                          <button
                            className="button-close"
                            onClick={() => setIsSelect(false)}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <hr className="hrEnd"/>
      <footer className="footerApp">
        
        <div className="footerInfo">
          <div className="footerContact">
            contacto: directivaluluenvios.com 
          </div>
          <div className="footerContact">Telefono: +56 9 4148 7677
          </div>
          <div className="footerAddress">
            Dirección Joaquin Rodriguez 2170, Macul
          </div>
        </div>
        <div className="footerIntegrations">
          <ul>
          <li>Woocommerce <img src={woo} alt=""/></li>
          <li>Shopyfy <img src={shopify} alt=""/></li>
          <li>Mercado Flex <img src={mecafle} alt=""/> </li>
          <li>Facebook Markets <img src={famakep} alt=""/> </li>
          <li>Jumpsellers <img src={jumpIcon} alt=""/> </li>
          <li>Fallabella <img src={fallabella} alt=""/> </li>
          <li>integraciones por medio de API <img src={apir} alt=""/> </li>
          </ul>
       
        </div>
      </footer>
    </div>
  );
};

export default TrackingView;
